import Vue from "vue";
import Vuex from "vuex";
import config from "@/store/config";
import car from "@/store/car";
import user from "@/store/user";
import content from "@/store/content";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    config,
    user,
    car,
    content,
  },
});
