import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "normalize.css";
import "@/assets/styles/global.scss";
import "@/plugins/axios";
import "bootstrap/dist/css/bootstrap.css";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import "@/plugins/moment";
import "@/plugins/components";
import axios from "@/plugins/axios";
import VueScrollTo from "vue-scrollto";
import "vue2-animate/dist/vue2-animate.min.css";

import VueSocialSharing from "vue-social-sharing";

import VueMask from "v-mask";
Vue.use(VueMask);
Vue.use(VueScrollTo);
Vue.use(VueSocialSharing);

Vue.config.productionTip = false;

Vue.prototype.$axios = axios;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
