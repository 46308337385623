import axios from "@/plugins/axios";
import { forEach } from "lodash";

export default {
  getGeo(cities, $store) {
    //@TODO Дописать для моб приложений
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        try {
          const { data } = await axios.baseInstance.get(
            `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${position.coords.latitude}&longitude=${position.coords.longitude}&localityLanguage=ru`
          );
          forEach(cities, (city) =>
            forEach(city.sites, (site) => {
              if (site.region_name === data.city) {
                console.log(site.region_name);
                $store.commit("config/changeCity", city.id);
              }
            })
          );
        } catch (e) {
          console.log(e);
        }
      });
    } else {
      return null;
    }
  },
};
