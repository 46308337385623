import axios from "@/plugins/axios";
import { find } from "lodash";

export default {
  namespaced: true,
  state: {
    cars: [],
    loading: false,
    free: [],
  },
  getters: {
    getCars(state) {
      return state.cars;
    },
    getCarsSortedByRate(state) {
      return state.cars.sort((a, b) => {
        return a.rates[1].summ - b.rates[1].summ;
      });
    },
    getLoadingCars(state) {
      return state.loading;
    },
    getFreeById: (state) => (id) => find(state.free, (car) => car.id === id),
  },
  mutations: {
    loadCars(state, payload) {
      return (state.cars = payload);
    },
    loadFree(state, payload) {
      return (state.free = payload);
    },
    changeLoading(state, payload) {
      return (state.loading = payload);
    },
  },
  actions: {
    async loadCars({ rootGetters, commit }) {
      commit("changeLoading", true);
      try {
        const response = await axios.api.cars({
          query: {
            site: rootGetters["config/getSite"],
          },
        });
        commit("loadCars", response.data);
        commit("changeLoading", false);
      } catch (e) {
        console.log(e);
      }
    },
    async loadFreeCars({ commit, rootGetters }, { from, to }) {
      try {
        const response = await axios.api.freeCars({
          query: { from, to, site: rootGetters["config/getSite"] },
        });
        commit("loadFree", response.data);
        console.log(response);
      } catch (e) {
        console.log(e);
      }
    },
  },
};
