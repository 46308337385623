export default {
  namespaced: true,
  state: {
    user: {},
  },
  getters: {
    isAuth(state) {
      return !!state.auth?.id;
    },
  },
  mutations: {},
  actions: {},
};
