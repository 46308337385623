import Vue from "vue";
import VueRouter from "vue-router";
import VueHead from "vue-head";

Vue.use(VueRouter);
Vue.use(VueHead, {
  separator: "-",
  complement: "БН Моторс",
});

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home"),
    meta: {
      title: "Главная",
    },
  },
  {
    path: "/catalog",
    name: "Catalog",
    component: () => import("@/views/CatalogPage"),
    meta: {
      title: "Каталог",
    },
  },
  {
    path: "/news",
    name: "News",
    component: () => import("@/views/NewsPage"),
  },
  {
    path: "/news/:id",
    name: "NewsDetail",
    component: () => import("@/views/NewsDetail"),
  },

  {
    path: "/events",
    name: "Events",
    component: () => import("@/views/EventsPage"),
  },
  {
    path: "/events/:id",
    name: "EventDetail",
    component: () => import("@/views/NewsDetail"),
  },
  {
    path: "/tariffs",
    name: "Tariffs",
    component: () => import("@/views/TariffPage"),
  },
  {
    path: "/rental-rules",
    name: "RentalRules",
    component: () => import("@/views/RentalRules"),
  },
  {
    path: "/presence-card",
    name: "PresenceCard",
    component: () => import("@/views/PresenceCard"),
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: () => import("@/views/Contacts"),
  },
  {
    path: "/contacts/:site",
    name: "ContactPage",
    component: () => import("@/views/ContactDetail"),
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "active",
  routes,
  scrollBehavior(from, to) {
    if (from.name !== to.name) {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
