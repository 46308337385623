import axios from "axios";
import apiService from "@/services/apiService";

const baseInstance = axios.create({
  baseURL: `${process.env.VUE_APP_AXIOS_SSL === "true" ? "https" : "http"}://${
    process.env.VUE_APP_AXIOS_DOMAIN
  }`,
});
const apiPrefix = "api";
export default {
  baseInstance,
  api: apiService(baseInstance)([
    ["news", "get", apiPrefix, ["articles"]],
    ["stocks", "get", apiPrefix, ["stocks"]],
    ["cities", "get", apiPrefix, ["regions"]],
    ["banners", "get", apiPrefix, ["banners"]],
    ["cars", "get", apiPrefix, ["cars"]],
    ["freeCars", "get", apiPrefix, ["cars", "free"]],
    ["mainStock", "get", apiPrefix, ["stock", "main"]],
    ["faq", "get", apiPrefix, ["faqs"]],
    ["sitesByRegion", "get", apiPrefix, ["region", "sites"]],
    ["maps", "get", apiPrefix, ["sites"]],
    ["rates", "get", apiPrefix, ["cars", "rates"]],
    ["sendCar", "post", apiPrefix, ["order"]],
    ["rules", "get", apiPrefix, ["include", "rentrules"]],
  ]),
};
