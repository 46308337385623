<template>
  <div class="sidebar" id="sidebar">
    <div class="sidebar__top">
      <div class="sidebar__logo">
        <router-link to="/">
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 292.2 73.7"
            enable-background="new 0 0 292.2 73.7"
            xml:space="preserve"
          >
            <g>
              <path
                fill="#EB661A"
                d="M0.1,0.5c0.3,0,0.6,0,0.8,0c13.5,0,27.1,0,40.6,0c1.3,0,2.6,0.1,3.8,0.3c1.1,0.1,2.3,0.3,3.4,0.4
		c2.4,0.4,4.7,0.9,7,1.8c1.9,0.7,3.6,1.8,5.3,3c0.4,0.3,0.8,0.6,1.3,0.9c2.6,1.9,4.3,4.5,5.2,7.5c0.7,2.2,0.9,4.4,0.7,6.6
		c-0.3,3.6-1.5,6.8-3.7,9.6c-1.3,1.6-2.8,2.9-4.6,4c-0.3,0.2-0.7,0.4-1,0.6c-0.1,0.1-0.2,0.2-0.4,0.3c0.1,0.1,0.2,0.1,0.3,0.1
		c0.6,0.2,1.2,0.4,1.7,0.7c1.4,0.7,2.8,1.5,4,2.5c3.5,2.6,5.7,6.1,6.7,10.3c0.3,1.3,0.5,2.7,0.5,4c0,3.4-0.7,6.6-2.4,9.6
		c-1.2,2.1-2.8,3.9-4.8,5.3c-1.9,1.4-4,2.4-6.2,3.2c-2.4,0.9-4.9,1.5-7.4,1.9c-1.6,0.2-3.3,0.4-4.9,0.5c-0.1,0-0.2,0.1-0.4,0.1
		c-15.2,0-30.5,0-45.7,0C0.1,49.4,0.1,25,0.1,0.5z M24.4,56.5c0.3,0,0.5,0,0.8,0c4.8,0,9.6,0,14.4,0c0.6,0,1.3,0,1.9-0.1
		c0.9-0.1,1.8-0.3,2.6-0.7c1-0.5,1.8-1.2,2.3-2.2c0.7-1.5,0.8-3,0.3-4.5c-0.4-1.3-1.3-2.3-2.6-2.9c-0.4-0.2-0.9-0.3-1.3-0.5
		c-1.1-0.3-2.2-0.4-3.4-0.4c-4.8,0-9.6,0-14.3,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.4,0.1C24.4,49,24.4,52.7,24.4,56.5z M24.4,17.9
		c0,0.2,0,0.4,0,0.5c0,0.8,0,1.6,0,2.3c0,2.4,0,4.7,0,7.1c0,0.2-0.1,0.4,0.1,0.7c0.2,0,0.4,0,0.6,0c4,0,7.9,0,11.9,0
		c0.6,0,1.2-0.1,1.8-0.1c1-0.2,1.9-0.5,2.8-1c1-0.7,1.7-1.5,2-2.7c0.2-0.8,0.2-1.5,0.1-2.3c-0.2-1.4-0.9-2.5-2.1-3.3
		c-0.5-0.3-0.9-0.5-1.5-0.7c-1-0.3-2-0.5-3.1-0.5c-4,0-8,0-12.1,0C24.8,17.9,24.6,17.9,24.4,17.9z"
              />
              <path
                fill="#EB661A"
                d="M73.9,73.9c0-0.2,0-0.5,0-0.7c0-23.9,0-47.9,0-71.8c0-0.3,0-0.5,0-0.8c0.1,0,0.3-0.1,0.4-0.1
		c0.2,0,0.3,0,0.5,0c6.2,0,12.5,0,18.7,0c0.2,0,0.5,0,0.7,0c1.4,1.9,3,3.7,4.4,5.4c2.3,2.8,4.7,5.6,7,8.4c1.5,1.8,3,3.5,4.5,5.3
		c2.1,2.6,4.2,5.1,6.3,7.7c1.5,1.9,3,3.7,4.6,5.5c0.1,0.2,0.3,0.3,0.4,0.4c0.1,0,0.1,0,0.1-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1
		c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c0-10.5,0-21,0-31.5c0,0,0,0,0-0.1
		c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0.1-0.1
		c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0c7.5,0,15,0,22.4,0c0.2,0,0.5,0,0.7,0c0,0.2,0,0.3,0,0.4c0,0.2,0,0.5,0,0.7
		c0,24.1,0,48.2,0,72.2c-6.7,0-13.4,0-20.1,0c-0.4-0.3-0.7-0.6-1-1c-0.1-0.2-0.3-0.3-0.4-0.5c-1.5-1.8-3.1-3.7-4.6-5.5
		c-3.9-4.7-7.8-9.4-11.7-14.1c-3.1-3.8-6.3-7.6-9.4-11.3c-0.1-0.1-0.2-0.2-0.3-0.4c-0.2,0.3-0.1,0.5-0.1,0.8c0,10.4,0,20.8,0,31.2
		c0,0.3,0,0.6-0.1,0.8C89.8,73.9,81.8,73.9,73.9,73.9z"
              />
              <path
                fill="#EB661A"
                d="M292.4,0.4c-2,2.8-4,5.5-6,8.3c-2.8,3.9-5.6,7.7-8.4,11.6c-1.6,2.2-3.2,4.5-4.8,6.7
		c-0.6,0.8-1.1,1.5-1.7,2.3c-0.1,0.2-0.3,0.4-0.4,0.6c0,0,0,0-0.1,0c0,0,0,0-0.1,0c-37.7,0-75.4,0-113.1,0c-0.1,0-0.2,0-0.3,0
		c0,0,0,0-0.1-0.1c0-0.1,0-0.2,0-0.3c0-0.2,0-0.3,0-0.5c0-9.4,0-18.7,0-28.1c0-0.3,0-0.6,0-0.8c45,0,90,0,135,0
		C292.4,0.2,292.4,0.3,292.4,0.4z M237.8,6.4c-0.1,0.2-0.2,0.3-0.3,0.5c-0.8,1.7-1.5,3.4-2.2,5.1c-1.3,2.9-2.5,5.8-3.8,8.7
		c-0.1,0.2-0.1,0.4-0.4,0.6c-1.4-2.4-2.8-4.8-4.2-7.3c0.4-0.7,0.8-1.3,1.2-1.9c0.4-0.6,0.7-1.2,1.1-1.8c0.4-0.6,0.8-1.3,1.2-1.9
		c0.4-0.6,0.8-1.2,1.1-1.9c-0.6-0.1-4.9-0.1-5.4,0c-0.1,0.2-0.3,0.5-0.4,0.8c-0.8,1.5-1.7,2.9-2.5,4.4c-0.1,0.2-0.2,0.4-0.4,0.6
		c-0.5,0-1,0.1-1.5-0.1c0-0.2,0-0.5,0-0.7c0-1.5,0-3,0-4.6c0-0.2,0-0.3,0-0.5c-0.1,0-0.2,0-0.2,0c-1.6,0-3.1,0-4.7,0
		c-0.1,0-0.2,0-0.3,0.1c0,0.1,0,0.2,0,0.4c0,5,0,10,0,15.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0.1,0,0.1,0c1.5,0,3,0,4.5,0
		c0.2,0,0.3,0,0.5,0c0,0,0,0,0.1,0c0,0,0,0,0.1-0.1c0-0.2,0-0.4,0-0.6c0-1.5,0-2.9,0-4.4c0-0.2,0-0.3,0-0.5c0.5,0,0.9,0,1.4,0
		c0.1,0.2,0.2,0.4,0.3,0.6c0.8,1.5,1.6,3,2.4,4.4c0.1,0.2,0.2,0.4,0.3,0.6c1.8,0,3.5,0,5.2,0c0.2-0.1,0.2-0.4,0.4-0.5
		c0.1,0.2,0.3,0.3,0.4,0.5c1.6,0,3.1,0,4.7,0c0.1-0.2,0.2-0.4,0.3-0.7c0.2-0.5,0.4-1.1,0.6-1.6c0.1-0.1,0.1-0.3,0.2-0.4
		c0.1,0,0.1,0,0.2,0c1.8,0,3.6,0,5.4,0c0.1,0,0.2,0,0.3,0.1c0.4,0.9,0.7,1.8,1.1,2.7c0.2,0,0.4,0,0.5,0c1.6,0,3.1,0,4.7,0
		c0,0,0,0,0.1,0c0,0,0,0,0.1-0.1c-0.5-1.3-1.1-2.6-1.7-3.9c-0.6-1.3-1.1-2.6-1.7-3.9c-0.6-1.3-1.1-2.6-1.7-3.9
		c-0.6-1.3-1.1-2.6-1.7-3.9C241.2,6.4,239.6,6.4,237.8,6.4z M205.6,22.5c0.7,0,1.7-0.1,2.7-0.4c4.2-1.3,6.5-5.1,5.8-9.3
		c-0.5-2.8-2.2-4.8-4.9-5.9c-2.3-1-4.7-1-7.1-0.2c-1.5,0.5-2.8,1.4-3.8,2.6c-1.4,1.8-1.9,3.9-1.7,6.1c0.4,2.8,1.8,4.8,4.3,6.1
		C202.2,22.1,203.7,22.4,205.6,22.5z M172.9,22c0.1,0,0.2,0.1,0.3,0.1c1.5,0,3.1,0,4.6,0c0.1,0,0.2,0,0.3-0.1c0-0.2,0-0.4,0-0.6
		c0-4.9,0-9.9,0-14.8c0,0,0,0,0-0.1c0,0,0,0-0.1-0.1c-0.2,0-0.4,0-0.7,0c-2.7,0-5.5,0-8.2,0c-1.8,0-3.7,0-5.5,0
		c-0.2,0-0.4-0.1-0.6,0.1c0,0.2,0,0.4,0,0.7c0,3.8,0,7.7,0,11.5c0,1,0,1.9,0,2.9c0,0.1,0,0.3,0.1,0.5c0.4,0,0.7,0,1.1,0
		c0.3,0,0.7,0,1,0c0.3,0,0.7,0,1,0c0.3,0,0.7,0,1,0c0.3,0,0.7,0,1-0.1c0-0.2,0-0.5,0-0.7c0-1.4,0-2.9,0-4.3c0-2,0-3.9,0-5.9
		c0-0.2-0.1-0.4,0.1-0.6c0.1,0,0.1,0,0.1,0c1.4,0,2.8,0,4.2,0c0.1,0,0.2,0,0.3,0.1c0,0.8,0,1.5,0,2.3c0,0.8,0,1.5,0,2.3s0,1.5,0,2.3
		c0,0.8,0,1.5,0,2.3C172.8,20.5,172.8,21.3,172.9,22z M186.1,18.3c0.3,0,0.6,0,0.8,0c1,0,1.9,0,2.9-0.1c1-0.1,2-0.4,3-0.9
		c1.3-0.7,2.2-1.8,2.6-3.3c0.2-0.7,0.2-1.4,0.2-2.1c-0.1-1.2-0.5-2.3-1.3-3.2c-0.6-0.7-1.4-1.2-2.3-1.6c-1.1-0.4-2.2-0.6-3.3-0.6
		c-2.5,0-4.9,0-7.4,0c-0.1,0-0.3,0-0.4,0c0,0.2-0.1,0.3-0.1,0.5c-0.1,3.8,0,14.5,0.1,15.2c0.4,0,0.8,0,1.3,0c0.4,0,0.9,0,1.3,0
		s0.9,0,1.3,0c0.4,0,0.9,0,1.3,0C186.1,20.8,186.1,19.6,186.1,18.3z M263.8,6.4c-0.9-0.1-13.8,0-14.3,0c-0.1,0.4,0,0.9,0,1.3
		c0,0.4,0,0.9,0,1.3c0,0.4-0.1,0.9,0.1,1.4c0.2,0,0.5,0,0.7,0c1.1,0,2.2,0,3.3,0c0.2,0,0.3,0,0.5,0.1c0,0.3,0,0.5,0,0.7
		c0,1.7,0,3.4,0,5.1c0,1.7,0,3.4,0,5.2c0,0.2-0.1,0.4,0.1,0.6c0,0,0.1,0,0.1,0c1.6,0,3.1,0,4.7,0c0.1,0,0.2,0,0.3-0.1
		c0-0.3,0-0.5,0-0.7c0-2.9,0-5.7,0-8.6c0-0.6,0-1.2,0-1.8c0-0.1,0-0.2,0.1-0.4c0.5,0,1,0,1.5,0c0.5,0,1,0,1.4,0c0.5,0,1,0.1,1.5-0.1
		c0-0.1,0.1-0.1,0.1-0.2c0-1.2,0-2.4,0-3.6C263.9,6.6,263.8,6.5,263.8,6.4z"
              />
              <path
                fill="#EB661A"
                d="M239.8,73.5c-0.7,0.1-81.8,0.1-82.4,0c0-0.1,0-0.2,0-0.4c0-0.2,0-0.3,0-0.5c0-9.4,0-18.7,0-28.1
		c0-0.3,0-0.5,0-0.8c0.1,0,0.1-0.1,0.2-0.1c34.4,0,68.8,0,103.2,0c0.1,0,0.1,0,0.2,0.1c0,0.1-0.1,0.2-0.1,0.3
		c-0.2,0.3-0.3,0.5-0.5,0.8c-2.8,3.9-5.6,7.8-8.4,11.6c-3.6,5-7.2,10-10.8,15C240.8,72.1,240.3,72.8,239.8,73.5z M183.8,66.8
		c0.3,0,0.5,0,0.7,0c2.4,0,4.8,0,7.2,0c0.7,0,1.3,0,2-0.1c1-0.1,1.9-0.4,2.7-0.8c1.2-0.7,1.9-1.7,2-3c0.2-1.6-0.4-3.2-2-4
		c-0.2-0.1-0.3-0.2-0.6-0.3c0.1-0.1,0.2-0.2,0.3-0.2c1.2-0.8,1.7-2,1.6-3.4c-0.1-1.1-0.6-2-1.5-2.6c-0.6-0.4-1.2-0.7-1.8-0.8
		c-0.8-0.2-1.7-0.3-2.5-0.3c-2.6,0-5.2,0-7.8,0c-0.1,0-0.2,0-0.4,0C183.7,51.8,183.7,66.2,183.8,66.8z M223,67.1
		c1,0,2.1-0.1,3.1-0.4c3.7-1.2,6.2-4.5,5.7-8.8c-0.3-2.8-1.8-4.9-4.3-6.1c-2.6-1.3-5.3-1.4-8-0.4c-3.2,1.2-5.7,4.3-5.2,8.7
		c0.4,2.9,2,5,4.7,6.2C220.2,66.9,221.5,67.1,223,67.1z M168.8,66.8c0.4-0.9,0.7-1.8,1-2.7c0.1,0,0.2,0,0.3,0c1.8,0,3.7,0,5.5,0
		c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0.4,0.9,0.7,1.8,1.1,2.6c0.2,0,0.5,0,0.7,0c0.2,0,0.4,0,0.7,0c0.2,0,0.5,0,0.7,0c0.2,0,0.4,0,0.7,0
		s0.4,0,0.7,0c0.2,0,0.4,0,0.7,0c0.2,0,0.5,0,0.7,0c0.2,0,0.4,0,0.6,0c0-0.1,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2
		c-2.2-5.1-4.4-10.1-6.7-15.2c0,0,0,0,0,0c0,0,0,0-0.1,0c-0.8-0.1-4.7,0-5.2,0c-2.2,5.1-4.5,10.2-6.7,15.4c0,0.1,0,0.1,0,0.2
		C165.2,66.8,166.9,66.8,168.8,66.8z M203.8,66.7c0.1,0,0.2,0,0.2,0c1.5,0,3.1,0,4.6,0c0.1,0,0.2,0,0.3-0.1c0-0.2,0-0.4,0-0.7
		c0-1.9,0-3.8,0-5.7c0-1.5,0-3,0-4.5c0-0.2-0.1-0.4,0.1-0.6c0.7,0,1.4,0,2.2,0c0.7,0,1.5,0,2.2,0c0.1-0.6,0.1-3.5,0-4
		c-0.1,0-0.2,0-0.4,0c-3.2-0.1-13.3,0-13.9,0.1c-0.1,0.4,0,0.9-0.1,1.3c0,0.4,0,0.9,0,1.3c0,0.4-0.1,0.9,0.1,1.4c0.5,0,1,0,1.5,0
		c0.5,0,1,0,1.4,0c0.5,0,1,0,1.5,0c0.2,0.3,0.1,0.5,0.1,0.7c0,3.3,0,6.7,0,10C203.7,66.2,203.7,66.5,203.8,66.7z"
              />
              <path
                fill="#EB661A"
                d="M238.9,15.5c0.1-0.5,1.1-3.2,1.4-3.7c0,0,0.1,0.1,0.1,0.1c0.4,1.1,0.9,2.3,1.3,3.4c0,0.1,0,0.1,0,0.2
		C241.1,15.6,239.4,15.5,238.9,15.5z"
              />
              <path
                fill="#EB661A"
                d="M201.8,14.2c0-0.6,0.1-1.2,0.4-1.8c0.6-1.3,1.6-2,3.1-2.1c1.4,0,2.5,0.6,3.2,1.9c0.7,1.3,0.6,2.7,0,4.1
		c-0.6,1.2-1.6,1.8-3,1.8c-1.4,0-2.4-0.6-3-1.8C201.9,15.7,201.8,15,201.8,14.2z"
              />
              <path
                fill="#EB661A"
                d="M186.2,10.5c0.1,0,0.1-0.1,0.1-0.1c0.6,0,1.1,0,1.7,0c0.3,0,0.6,0,0.8,0.1c0.2,0,0.3,0.1,0.5,0.2
		c0.4,0.2,0.6,0.5,0.7,0.9c0.1,0.4,0.2,0.9,0,1.3c-0.2,0.8-0.8,1.1-1.5,1.2c-0.7,0.1-1.5,0-2.2,0c-0.1,0-0.1,0-0.2-0.1
		c0-0.1-0.1-0.1-0.1-0.2c0-1.1,0-2.1,0-3.2C186.1,10.7,186.2,10.6,186.2,10.5z"
              />
              <path
                fill="#EB661A"
                d="M189,63c0-0.8,0-1.5,0-2.3c0.1,0,0.3-0.1,0.4-0.1c0.8,0,1.7,0,2.5,0c0,0,0.1,0,0.1,0c0.4,0,0.8,0.1,1,0.6
		c0.2,0.5,0.2,1-0.1,1.4c-0.1,0.2-0.4,0.3-0.6,0.4c-0.1,0-0.3,0-0.5,0c-0.8,0-1.7,0-2.5,0C189.2,63.1,189.1,63,189,63z"
              />
              <path
                fill="#EB661A"
                d="M189,57.1c-0.1-0.6-0.1-1.7,0-2.3c0.1,0,0.3,0,0.4,0c0.5,0,1.1,0,1.6,0c0.1,0,0.1,0,0.2,0
		c0.9,0,1.4,0.7,1.2,1.5c-0.1,0.3-0.3,0.6-0.6,0.7c-0.2,0-0.3,0.1-0.5,0.1c-0.2,0-0.3,0-0.5,0C190.2,57.1,189.6,57.1,189,57.1z"
              />
              <path
                fill="#EB661A"
                d="M226.5,58.9c0,0.7-0.1,1.3-0.4,1.9c-0.6,1.3-1.7,2-3.1,2c-1.4,0-2.5-0.6-3.1-2c-0.6-1.3-0.6-2.6,0-3.9
		c0.6-1.3,1.7-2,3.2-2c1.5,0,2.5,0.7,3.1,2.1C226.4,57.7,226.5,58.3,226.5,58.9z"
              />
              <path
                fill="#EB661A"
                d="M174.2,60.2c-0.6,0.1-2.3,0.1-2.8,0c0.1-0.5,0.8-2.5,1.4-3.7c0.1,0.1,0.2,0.1,0.2,0.2
		c0.4,1.1,0.8,2.1,1.2,3.2C174.2,60,174.2,60.1,174.2,60.2z"
              />
            </g>
          </svg>
        </router-link>
      </div>
      <transition
        name="fade"
        enter-active-class="fadeInRight"
        leave-active-class="fadeOutRight"
      >
        <div
          class="sidebar__center"
          v-show="getShowMenu"
          style="animation-duration: 0.5s"
        >
          <div class="row">
            <div class="col-12 col-xl-12">
              <div class="row">
                <div class="col-1 d-none d-xl-block">
                  <div
                    class="step h-100"
                    :class="step >= 1 ? 'active' : ''"
                    data-index="1"
                  >
                    <span>Выберите регион и офис проката</span>
                  </div>
                </div>
                <div class="col-12 col-xl-11">
                  <div class="sidebar__city pt-lg-4">
                    <side-bar-city />
                  </div>
                </div>
                <div class="col-1 d-none d-xl-block">
                  <div
                    class="step h-100 step_second"
                    :class="step >= 2 ? 'active' : ''"
                    data-index="2"
                  >
                    <span>Укажите даты проката</span>
                  </div>
                </div>
                <div class="col-12 col-xl-11">
                  <div class="sidebar__calendar pt-4 pb-2">
                    <side-bar-calendar />
                  </div>
                </div>
                <div class="col-1 d-none d-xl-block">
                  <div
                    class="step h-100 step_second step_last"
                    :class="step === 3 ? 'active' : ''"
                    data-index="3"
                  >
                    <span
                      >Отсортировать каталог по доступности на даты
                      проката</span
                    >
                  </div>
                </div>

                <div class="col-12 col-xl-11 pt-4 d-none d-lg-block">
                  <button
                    class="button button_accent mt-3"
                    @click="changeDate"
                    :disabled="step !== 3"
                  >
                    Отсортировать
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="sidebar__menu">
            <side-bar-menu />
          </div>
        </div>
      </transition>
    </div>
    <div class="sidebar__button" @click="showMenu = !showMenu">
      <div class="d-flex justify-content-between align-items-center">
        <div class="px-2 sidebar__position">
          <span>{{ getActiveCity.name || "Выберите регион" }}</span>
          <template v-if="getActiveSite.name">
            <br />
            <span>{{ getActiveSite.name }}</span>
          </template>
          <template></template>
        </div>
        <svg
          width="25"
          height="22"
          viewBox="0 0 25 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24.75 0H0.25C0.1125 0 0 0.1125 0 0.25V2.25C0 2.3875 0.1125 2.5 0.25 2.5H24.75C24.8875 2.5 25 2.3875 25 2.25V0.25C25 0.1125 24.8875 0 24.75 0ZM24.75 19.5H0.25C0.1125 19.5 0 19.6125 0 19.75V21.75C0 21.8875 0.1125 22 0.25 22H24.75C24.8875 22 25 21.8875 25 21.75V19.75C25 19.6125 24.8875 19.5 24.75 19.5ZM24.75 9.75H0.25C0.1125 9.75 0 9.8625 0 10V12C0 12.1375 0.1125 12.25 0.25 12.25H24.75C24.8875 12.25 25 12.1375 25 12V10C25 9.8625 24.8875 9.75 24.75 9.75Z"
            fill="black"
          />
        </svg>
      </div>
    </div>
    <div class="sidebar__desc">
      <div class="d-flex">
        <div class="row">
          <div class="col-6">
            <a href="#" target="_blank">
              <img src="@/assets/images/app-store.png" alt="" />
            </a>
          </div>
          <div class="col-6">
            <a href="#" target="_blank">
              <img src="@/assets/images/google-play.png" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBarCity from "@/components/layout/SideBar/SideBarCity";
import SideBarMenu from "@/components/layout/SideBar/SideBarMenu";
import SideBarCalendar from "@/components/layout/SideBar/SideBarCalendar";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "SideBar",
  components: { SideBarCalendar, SideBarMenu, SideBarCity },
  data() {
    return {
      showMenu: false,
    };
  },
  computed: {
    ...mapGetters("config", ["getDate", "getActiveCity", "getActiveSite"]),
    getShowMenu() {
      if (window.innerWidth > 1200) return true;
      return this.showMenu;
    },
    getRouteName() {
      return this.$route.name;
    },
    isGetDate() {
      return this.$route.query.from && this.$route.query.to;
    },
    step() {
      if (!this.getActiveCity.id || !this.getActiveSite) {
        return 1;
      } else if (!this.getDate || !this.getDate.start || !this.getDate.end) {
        return 2;
      }
      return 3;
    },
  },
  methods: {
    ...mapActions("car", ["loadFreeCars"]),
    async changeDate() {
      if (this.$route.name !== "Home" && this.$route.name !== "Catalog") {
        await this.$router.push({
          name: "Catalog",
          query: {
            from: this.getDate.start,
            to: this.getDate.end,
          },
        });
      } else {
        if (
          this.getDate.start !== this.$route.query.from ||
          this.getDate.end !== this.$route.query.to
        ) {
          await this.$router.replace({
            name: this.$route.name,
            query: {
              ...this.$route.query,
              from: this.getDate.start,
              to: this.getDate.end,
            },
          });
        }
      }
      await this.loadFreeCars({
        from: this.$route.query.from,
        to: this.$route.query.to,
      });
      this.$scrollTo("#final");
    },
  },
  watch: {
    getRouteName() {
      return (this.showMenu = false);
    },
  },
};
</script>

<style scoped lang="scss">
.sidebar {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  bottom: 0;
  max-width: 345px;
  background-color: #fff;
  width: 100%;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include media(1200) {
    bottom: auto;
    right: 0;
    height: auto;
    max-width: 100vw;
    flex-direction: row;
    padding-top: 12px;
    padding-bottom: 12px;
    align-items: center;
  }

  &__top {
    padding-top: 5vh;
    @include media(1200) {
      padding: 0;
      display: flex;
      justify-content: space-between;
    }
  }

  &__logo {
    max-width: 253px;
    text-align: center;
    margin: 0 auto;

    svg {
      width: 100%;
      height: auto;
      @include media(1200) {
        max-width: 200px;
      }
      @include media(992) {
        max-width: 175px;
      }
    }
  }

  &__center {
    padding-top: 5vh;
    @include media(1200) {
      position: absolute;
      top: 100%;
      background-color: #fff;
      left: auto;
      right: 0;
      max-width: 600px;
      display: flex;
      flex-direction: column-reverse;
      padding: 0 15px;
      justify-content: flex-end;
      overflow: auto;
      min-height: calc(100vh - 60px);
    }
    @include media(500) {
      width: 100%;
    }
  }
  &__calendar {
    @include media(1200) {
      display: none;
    }
  }

  &__city,
  &__menu {
    position: relative;
    z-index: 5;
    padding: 1vh 0;
  }
  &__menu {
    position: static;
  }

  &__desc {
    padding: 1vh 0 2vh;
    @include media(1200) {
      display: none;
    }
    img {
      max-width: 100%;
    }
  }
  &__button {
    cursor: pointer;
    display: none;
    svg {
      vertical-align: middle;
    }
    @include media(1200) {
      display: block;
    }
  }
  &__position {
    font-size: 12px;
    text-align: right;
  }
}
.step {
  width: 1px;
  background-color: #000;
  position: relative;
  top: 0px;
  //bottom: -20px;
  &::after {
    content: attr(data-index);
    position: absolute;
    color: #fff;
    top: 3px;
    left: -2px;
  }
  &::before {
    content: "";
    width: 30px;
    height: 30px;
    background-color: #000;
    border-radius: 10em;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
  }
  span {
    position: absolute;
    left: 30px;
    top: 8px;
    font-size: em(12);
    width: 280px;
    color: #ff6600;
    font-weight: 500;
  }
  &.active {
    &::before {
      background-color: #ff6600;
    }
  }
  &_second {
    top: 0;
    bottom: 0;
    &::before {
      top: -5px;
    }
    &::after {
      top: -2px;
    }
    span {
      top: 0;
    }
  }
  &_last {
    background: transparent;
  }
}
</style>
