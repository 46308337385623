import { find, forEach } from "lodash";
import axios from "@/plugins/axios";
export default {
  namespaced: true,
  state: {
    news: [],
    newsCategory: [
      {
        name: "Все",
        id: undefined,
      },
    ],
    events: [],
    mainStock: {},
  },
  getters: {
    getNews(state) {
      return state.news;
    },
    getNewsCategory(state) {
      return state.newsCategory;
    },
    getEvents(state) {
      return state.events;
    },
    getMainStock(state) {
      return state.mainStock;
    },
  },
  mutations: {
    loadNews(state, payload) {
      return (state.news = payload);
    },
    loadEvents(state, payload) {
      return (state.events = payload);
    },
    loadNewsCategory(state, payload) {
      return state.newsCategory.push(payload);
    },
    loadMainStock(state, payload) {
      return (state.mainStock = payload);
    },
  },
  actions: {
    async loadNews({ commit, getters }) {
      try {
        const response = await axios.api.news({});
        commit("loadNews", response.data);
        forEach(response.data, (news) => {
          if (
            news.category &&
            !find(
              getters["getNewsCategory"],
              (type) => type.id === news.category.id
            )
          ) {
            commit("loadNewsCategory", news.category);
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
    async loadEvents({ commit }) {
      try {
        const response = await axios.api.stocks({});
        commit("loadEvents", response.data);
      } catch (e) {
        console.log(e);
      }
    },
    async loadMainStock({ commit }) {
      try {
        const response = await axios.api.mainStock({});
        commit("loadMainStock", response.data);
      } catch (e) {
        console.log(e);
      }
    },
  },
};
