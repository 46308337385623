import { render, staticRenderFns } from "./SideBarCity.vue?vue&type=template&id=a33071c8&scoped=true&"
import script from "./SideBarCity.vue?vue&type=script&lang=js&"
export * from "./SideBarCity.vue?vue&type=script&lang=js&"
import style0 from "./SideBarCity.vue?vue&type=style&index=0&id=a33071c8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a33071c8",
  null
  
)

export default component.exports